const LISTENERS = {};

/**
 * Name of all exposed events
 */
export const RECAPTCHA_LOADED = Symbol("RECAPTCHA_LOADED");

export const dispatch = (eventName, payload) => {
  if (typeof LISTENERS[eventName] === "undefined") {
    return;
  }
  LISTENERS[eventName].forEach((fn) => {
    fn(payload);
  });
};

export const observe = (eventName, listener) => {
  if (typeof LISTENERS[eventName] === "undefined") {
    LISTENERS[eventName] = [];
  }
  LISTENERS[eventName].push(listener);
};
