import { map, uuid } from "@grrr/utils";

const DEFAULT_LOCALE = "nl";

const ERROR_MESSAGES = {
  valueMissing: {
    nl: "Dit is een verplicht veld",
    en: "This is a required field",
  },
  invalidEmail: {
    nl: "Dit is geen geldig e-mailadres",
    en: "Invalid email address",
  },
  patternMismatch: {
    nl: "Het patroon van dit veld is niet correct",
    en: "The pattern of this field is not correct",
  },
};

const cleanInputFromErrors = (input) => {
  if (input.errorNode) {
    input.errorNode.remove();
  }
  input.removeAttribute("aria-invalid");
  input.removeAttribute("aria-describedby");
};

const getErrorMessage = (input, locale) => {
  if (!locale) {
    locale = DEFAULT_LOCALE;
  }

  const { validity } = input;
  let errorMessage = "";
  if (validity.valueMissing) {
    errorMessage = ERROR_MESSAGES.valueMissing[locale];
  }
  if (validity.typeMismatch && input.type === "email") {
    errorMessage = ERROR_MESSAGES.invalidEmail[locale];
  }
  if (validity.patternMismatch) {
    errorMessage = ERROR_MESSAGES.patternMismatch[locale];
  }
  return errorMessage;
};

const showError = (input, locale) => {
  if (input.errorNode) {
    input.errorNode.remove();
  }
  const field = input.parentNode;
  const errorMessage = getErrorMessage(input, locale);
  const errorNode = document.createElement("span");
  const errorId = `error-${uuid()}`;
  errorNode.id = errorId;
  errorNode.innerHTML = errorMessage;
  errorNode.setAttribute("role", "alert");
  input.errorNode = errorNode;
  input.setAttribute("aria-invalid", true);
  input.setAttribute("aria-describedby", errorId);
  field.appendChild(errorNode);

  input.addEventListener("keyup", checkValidity); // eslint-disable-line no-use-before-define
};

const checkValidity = (locale) => (event) => {
  const input = event.target;
  const isValid = input.checkValidity();
  if (!isValid) {
    return showError(input, locale);
  }
  return cleanInputFromErrors(input);
};

export const enhancer = (form) => {
  const inputs = form.querySelectorAll("input, textarea");
  const locale = form.getAttribute("data-locale");
  map(
    (input) => {
      input.addEventListener("blur", checkValidity(locale));
    },
    [...inputs],
  );
};
