export const getScrollPosition = () => {
  const supportPageOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";

  /* eslint-disable no-nested-ternary */
  const x = supportPageOffset
    ? window.pageXOffset
    : isCSS1Compat
      ? document.documentElement.scrollLeft
      : document.body.scrollLeft;

  const y = supportPageOffset
    ? window.pageYOffset
    : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;
  return {
    x,
    y,
  };
  /* eslint-enable no-nested-ternary */
};

export const closest = (elm, selector, includingSelf = true) => {
  let matchesFn;

  // find vendor prefix
  [
    "matches",
    "webkitMatchesSelector",
    "mozMatchesSelector",
    "msMatchesSelector",
    "oMatchesSelector",
  ].some((fn) => {
    if (typeof document.body[fn] === "function") {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  // check self
  if (
    includingSelf &&
    elm[matchesFn] !== undefined &&
    elm[matchesFn](selector)
  ) {
    return elm;
  }

  // traverse parents
  /* eslint-disable no-param-reassign */
  while (elm !== null) {
    const parent = elm.parentElement;
    if (
      parent !== null &&
      parent[matchesFn] !== undefined &&
      parent[matchesFn](selector)
    ) {
      return parent;
    }
    elm = parent;
  }

  return null;
};

const curry = (fn) => {
  const arity = fn.length;
  return (...args) => {
    return args.length < arity ? curry(fn.bind(null, ...args)) : fn(...args);
  };
};

export const head = (a) => a[0];

export const forEach = (a, fn) => Array.prototype.forEach.call(a, fn);

export const filter = (a, fn) => Array.prototype.filter.call(a, fn);

export const not =
  (fn) =>
  (...args) =>
    !fn(...args);

export const compose =
  (...fns) =>
  (arg) =>
    fns.reverse().reduce((acc, fn) => fn(acc), arg);

export const map = curry((fn, xs) => Array.prototype.map.call(xs, fn));

export const prop = curry((x, o) => o[x]);

export const objectByKey = (arr, key) => {
  return arr.reduce((prev, curr) => {
    if (typeof curr[key] === "undefined") {
      return prev;
    }
    prev[curr[key]] = curr;
    return prev;
  }, {});
};

export const concat = (arr, x) => arr.concat([x]);

export const range = (max, n = 0) => {
  if (n > max) {
    throw new Error("max should be less than start");
  }
  return n >= max ? [] : [n].concat(range(max, n + 1));
};

export const preventDefault = (e) => e.preventDefault();

export const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout((e) => fn(...args), delay);
  };
};

export const throttle = (fn, limit) => {
  let lastFunc;
  let lastRan;
  return function (...args) {
    const context = this;
    if (!lastRan) {
      fn.apply(context, ...args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        () => {
          if (Date.now() - lastRan >= limit) {
            fn.apply(context, ...args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan),
      );
    }
  };
};

export const parseJSON = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.warn("This JSON does not appear to be valied");
    console.error(e);
    return undefined;
  }
};

/**
 * Disable focus for all other nodes
 */
export const disableDocument = (target) => {
  /* eslint-disable no-underscore-dangle */
  const nodes = [...document.querySelectorAll(`*:not([tabindex="-1"])`)].filter(
    (node) => !target.contains(node),
  );
  forEach(nodes, (node) => {
    node.setAttribute("tabindex", "-1");
    node._isDisabled = true;
  });
};

/**
 * Re-enable focus for all other nodes
 */
export const enableDocument = (target) => {
  /* eslint-disable no-underscore-dangle */
  const nodes = [...document.querySelectorAll(`*[tabindex="-1"]`)].filter(
    (node) => node._isDisabled,
  );
  forEach(nodes, (node) => {
    node.removeAttribute("tabindex");
    delete node._isDisabled;
  });
};

/**
 * Simple `template` function to transform html strings to DOM elements.
 * For the escaping part, see: https://stackoverflow.com/a/1787336
 */
export const htmlToElements = (html, parseEscapedCharacters = false) => {
  const placeholder = document.createElement("div");
  const parser = new DOMParser();
  const dom = parser.parseFromString(
    `<!doctype html><body>${html}`,
    `text/html`,
  );

  placeholder.innerHTML = parseEscapedCharacters
    ? dom.body.textContent
    : dom.body.innerHTML;

  const elements = placeholder.children;
  return elements.length === 1 ? elements[0] : elements;
};

/**
 * Stop the body from scrolling (mostly used when a popup, modal or navigation is open)
 */
export const disableScroll = () => {
  document.body.style.overflow = "hidden";
};

/**
 * Enable the body to scroll again
 */
export const enableScroll = () => {
  document.body.style.overflow = "auto";
};
