import { uuid } from "@grrr/utils";
import { observe, RECAPTCHA_LOADED } from "./observer";

/**
 * @param {string} recaptchaSiteKey
 * @returns Promise<string>
 */
const generateRecaptchaToken = async (recaptchaSiteKey) =>
  new Promise((resolve) => {
    grecaptcha.ready(
      () =>
        void (async () => {
          const token = await grecaptcha.execute(recaptchaSiteKey, {
            action: "action",
          });

          resolve(token);
        })(),
    );
  });

const FETCH_PARAMS = {
  method: "post",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

/**
 * Checks that an element has a non-empty `name`.
 * @param  {Element} element  the element to check
 * @return {Bool}             true if the element is an input, false if not
 */
const isValidElement = (element) => {
  return element.name;
};

/**
 * Checks if an element’s value can be saved (e.g. not an unselected checkbox).
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the value should be added, false if not
 */
const isValidValue = (element) => {
  return !["checkbox", "radio"].includes(element.type) || element.checked;
};

const isCheckbox = (element) => element.type === "checkbox";
const isFileUpload = (element) => element.type === "file";

const cleanFileInputFromErrors = (input) => {
  if (input.errorNode) {
    input.errorNode.remove();
  }
  input.removeAttribute("aria-invalid");
  input.removeAttribute("aria-describedby");
};

function validateFiles(element) {
  let valid = true;
  for (let i = 0; i < element.files.length; i += 1) {
    cleanFileInputFromErrors(element);
    if (element.files[i].size >= 4194304) {
      /* 4MB limit */
      valid = false;

      if (element.errorNode) {
        element.errorNode.remove();
      }
      const field = element.parentNode;
      const errorMessage = "Bestand overschrijft limiet van 4MB";
      const errorNode = document.createElement("span");
      const errorId = `error-${uuid()}`;
      errorNode.id = errorId;
      errorNode.innerHTML = errorMessage;
      errorNode.setAttribute("role", "alert");
      element.errorNode = errorNode;
      element.setAttribute("aria-invalid", true);
      element.setAttribute("aria-describedby", errorId);
      field.appendChild(errorNode);

      break;
    }
  }

  return valid;
}

/*
const isMultiSelect = element => element.options && element.multiple;
*/

/**
 * Some input names for multiselection needs [] at the end of the name for
 * a normal post request. But with for javascript fetch we need to remove
 * these brackets for the JSON payload.
 */
const removeArrayNotation = (string) => {
  return string.replace("[]", "");
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const tmp = {};

/**
 *
 * @param {HTMLFormElement} form
 * @returns {Object}
 */
const formToJSON = (form) =>
  [].reduce.call(
    form.elements,
    (data, element) => {
      if (isValidElement(element) && isValidValue(element)) {
        tmp.images = "";
        /*
         * Some fields allow for more than one value, so we need to check if this
         * is one of those fields and, if so, store the values as an array.
         */
        if (isCheckbox(element)) {
          data[removeArrayNotation(element.name)] = (
            data[removeArrayNotation(element.name)] || []
          ).concat(element.value);
        } else if (isFileUpload(element) && validateFiles(element)) {
          for (let i = 0; i < element.files.length; i += 1) {
            const file = element.files[i];

            getBase64(file).then((imageData) => {
              tmp.images = `${tmp.images}${String(imageData)}|`;
            });
          }
        } else {
          data[element.name] = element.value;
        }
      }
      return data;
    },
    {},
  );

const createAlertElement = (form) => {
  const el = document.createElement("span");
  const alertId = `alert-${uuid()}`;
  el.id = alertId;
  el.classList.add("form-alert");
  el.setAttribute("role", "alert");
  el.setAttribute("aria-hidden", "true");
  form.setAttribute("aria-describedby", alertId);
  form.appendChild(el);
  return el;
};

const Form = (form) => {
  const alertElement = createAlertElement(form);
  const successUrl = form.getAttribute("data-success-url");
  const submitButton = form.querySelector('[type="submit"]');
  const honeypotInput = form.querySelector("input[data-nocomplete]");
  const recaptchaSiteKey = form.getAttribute("data-recaptcha-sitekey");

  const images = document.getElementById("images");
  if (images) {
    images.onchange = function () {
      const files = document.getElementById("images");
      validateFiles(files);
    };
  }

  const enableButton = () => {
    submitButton.disabled = false;
  };
  const disableButton = () => {
    submitButton.disabled = "disabled";
  };

  const hideAlert = () => {
    form.removeAttribute("aria-describedby");
    form.removeAttribute("aria-invalid");
    alertElement.setAttribute("aria-hidden", "true");
  };

  const showAlert = (message, type) => {
    if (type === "error") {
      form.setAttribute("aria-invalid", "true");
      form.setAttribute("aria-describedby", alertElement.id);
    }
    alertElement.setAttribute("aria-hidden", "false");
    alertElement.setAttribute("data-type", type);
    alertElement.textContent = message;
  };

  const pause = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const getData = async (formDataObject) => {
    let dataElement = await JSON.stringify(formDataObject);
    await pause(10);
    if (tmp.images !== "") {
      const imagesBase64 = tmp.images.split("|");

      let imagesJson = ', "images":[';
      for (let i = 0; i < imagesBase64.length - 1; i += 1) {
        const imgBase64 = imagesBase64[i];
        imagesJson = `${imagesJson}"${String(imgBase64)}"`;
        if (i + 1 !== imagesBase64.length - 1) {
          imagesJson += ",";
        } else {
          imagesJson += "]}";
        }
      }
      dataElement = dataElement.slice(0, -1) + imagesJson;
    }
    await pause(10);
    return dataElement;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    hideAlert();
    disableButton();
    if (honeypotInput) {
      honeypotInput.value = "";
    }

    /**
     * Form data, taken from the form element
     */
    const formDataObject = await formToJSON(form);

    if (recaptchaSiteKey) {
      const recaptchaToken = await generateRecaptchaToken(recaptchaSiteKey);
      if (!recaptchaToken) {
        showAlert(
          "reCAPTCHA token generation failed. Please try again.",
          "error",
        );
        return;
      }
      formDataObject["g-recaptcha-response"] = recaptchaToken;
    }

    await fetch(form.getAttribute("action"), {
      ...FETCH_PARAMS,
      body: await getData(formDataObject),
    }).then((response) => {
      if (!response.ok) {
        enableButton();
        return response
          .json()
          .then((result) => {
            showAlert(result.message, "error");
          })
          .catch((error) => {
            showAlert(error, "error");
          });
      }
      if (successUrl) {
        window.location.href = successUrl;
        return undefined;
      }
      enableButton();
      return response
        .json()
        .then((result) => {
          showAlert(result, "success");
          form.reset();
        })
        .catch((error) => {
          showAlert(error, "error");
        });
    });
  };

  return {
    init: () => {
      form.addEventListener("submit", handleSubmit);
      if (recaptchaSiteKey) {
        disableButton();

        observe(RECAPTCHA_LOADED, () => {
          enableButton();
        });
      }
    },
  };
};

export const enhancer = (form) => {
  const formInstance = Form(form);
  formInstance.init();
};
