import { observe, dispatch, RECAPTCHA_LOADED } from "./observer";

function createScript(recaptchaSitekey) {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSitekey}`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

function notifyWhenRecaptchaIsLoaded() {
  if (typeof window.grecaptcha !== "undefined") {
    console.log("reCAPTCHA loaded.");
    return dispatch(RECAPTCHA_LOADED);
  }

  setTimeout(notifyWhenRecaptchaIsLoaded, 100);

  return null;
}
export const enhancer = (container) => {
  const recaptchaSitekey = container.getAttribute("data-recaptcha-sitekey");
  if (!recaptchaSitekey) {
    console.error("No reCAPTCHA sitekey found.");
    return;
  }
  createScript(recaptchaSitekey);
  notifyWhenRecaptchaIsLoaded();
};
